<template>
  <a-select
    show-search
    :filter-option="filterOption"
    :placeholder="selfPlaceholder"
    style="width: 100%"
    @change="selectUnit"
    :disabled="disabled"
    v-model="value"
  >
    <a-select-option v-for="item in list" :value="item.name" :key="item.name">
      {{ item.nameFull }}
    </a-select-option>
  </a-select>
</template>

<script>
import api from '@service/api'
import http from '@service/axios'

export default {
  props: ['code', 'placeholder', 'disabled'],
  data() {
    return {
      value: undefined,
      list: [],
    }
  },
  computed: {
    selfPlaceholder() {
      return this.placeholder
    },
  },
  mounted() {
    this.getList()
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    getList() {
      http({
        url: api.getUnitList,
        data: {},
        success: (res) => {
          this.list = res.result
        },
      })
    },
    selectUnit(value) {
      this.$emit('update:code', value)
      let data = this.list.find((item) => item.name === value) || {}
      this.$emit('change', data)
    },
    reset() {
      this.value = undefined
    },
  },
  watch: {
    code: {
      handler(val) {
        this.$nextTick(() => {
          this.value = val
        })
      },
      immediate: true,
    },
  },
}
</script>
