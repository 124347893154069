<template>
  <div class="serach-select">
    <a-auto-complete
      v-if="textAvailable || list.length === 0"
      style="width: 100%"
      :filter-option="filterOption"
      v-model="localValue"
      ref="skuSelect"
      @blur="handleInputChange"
      @popupScroll="handlePopupScroll"
      @search="handleSearch"
      @select="handleSelect"
      :disabled="!deletable"
    >
      <template slot="dataSource">
        <a-select-option :value="item.styleId + ''" :key="item.styleId" v-for="item in frontDataZ">
          {{ item.styleTypeName }}
        </a-select-option>
      </template>
    </a-auto-complete>
    <a-select
      v-else
      show-search
      ref="skuSelect"
      :style="cusStyle"
      v-model="localValue"
      :filter-option="filterOption"
      @change="handleChange"
      @popupScroll="handlePopupScroll1"
      :disabled="!deletable"
      @search="handleSearch1"
    >
      <a-select-option :value="item.styleId" :key="item.styleId" v-for="item in frontDataZ1">
        {{ item.styleTypeName }}
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
export default {
  name: 'searchSelect',
  props: ['list', 'deletable', 'value', 'textAvailable'],
  data() {
    return {
      dataZ: JSON.parse(JSON.stringify(this.list)),
      dataZ1: JSON.parse(JSON.stringify(this.list)),
      frontDataZ: [],
      frontDataZ1: [],
      sourceOwnerSystems: [],
      valueData: '',
      valueData1: '',
      treePageSize: 300,
      treePageSize1: 300,
      scrollPage: 1,
      scrollPage1: 1,
    }
  },
  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(val) {
        if (String(val).indexOf('\\') !== -1) {
          this.$warning("禁止在规格值里面出现'\\'符号")
          return
        }
        this.$emit('update:value', val)
      },
    },
    cusStyle() {
      return {
        width: '100%',
      }
    },
  },
  mounted() {
    this.frontDataZ = this.dataZ.slice(0, this.treePageSize)
    this.frontDataZ1 = this.dataZ1.slice(0, this.treePageSize1)
  },
  methods: {
    handlePopupScroll(e) {
      const { target } = e
      const scrollHeight = target.scrollHeight - target.scrollTop
      const clientHeight = target.clientHeight
      // 下拉框不下拉的时候
      if (scrollHeight === 0 && clientHeight === 0) {
        this.scrollPage = 1
      } else {
        // 当下拉框滚动条到达底部的时候
        if (scrollHeight < clientHeight + 5) {
          this.scrollPage = this.scrollPage + 1
          const scrollPage = this.scrollPage // 获取当前页
          const treePageSize = this.treePageSize * (scrollPage || 1) // 新增数据量
          const newData = [] // 存储新增数据
          let max = '' // max 为能展示的数据的最大条数
          if (this.dataZ.length > treePageSize) {
            // 如果总数据的条数大于需要展示的数据
            max = treePageSize
          } else {
            // 否则
            max = this.dataZ.length
          }
          // 判断是否有搜索
          if (this.valueData) {
            this.allDataZ.forEach((item, index) => {
              if (index < max) {
                // 当data数组的下标小于max时
                newData.push(item)
              }
            })
          } else {
            this.dataZ.forEach((item, index) => {
              if (index < max) {
                // 当data数组的下标小于max时
                newData.push(item)
              }
            })
          }

          this.frontDataZ = newData // 将新增的数据赋值到要显示的数组中
        }
      }
    },
    handleSearch(val) {
      this.valueData = val
      if (!val) {
        // this.showTabelCiList();
        this.dataZ = JSON.parse(JSON.stringify(this.list))
      } else {
        this.frontDataZ = []
        this.scrollPage = 1
        this.dataZ.forEach((item) => {
          if (item.styleTypeName.indexOf(val) >= 0) {
            this.frontDataZ.push(item)
          }
        })
        this.allDataZ = this.frontDataZ
        this.frontDataZ = this.frontDataZ.slice(0, 100)
      }
    },
    handleSelect(value) {
      let item = this.list.find((item) => item.styleId === +value)
      this.localValue = item.styleTypeName
    },
    handlePopupScroll1(e) {
      const { target } = e
      const scrollHeight = target.scrollHeight - target.scrollTop
      const clientHeight = target.clientHeight
      // 下拉框不下拉的时候
      if (scrollHeight === 0 && clientHeight === 0) {
        this.scrollPage1 = 1
      } else {
        // 当下拉框滚动条到达底部的时候
        if (scrollHeight < clientHeight + 5) {
          this.scrollPage1 = this.scrollPage1 + 1
          const scrollPage1 = this.scrollPage1 // 获取当前页
          const treePageSize1 = this.treePageSize1 * (scrollPage1 || 1) // 新增数据量
          const newData = [] // 存储新增数据
          let max = '' // max 为能展示的数据的最大条数
          if (this.dataZ1.length > treePageSize1) {
            // 如果总数据的条数大于需要展示的数据
            max = treePageSize1
          } else {
            // 否则
            max = this.dataZ1.length
          }
          // 判断是否有搜索
          if (this.valueData1) {
            this.allDataZ1.forEach((item, index) => {
              if (index < max) {
                // 当data数组的下标小于max时
                newData.push(item)
              }
            })
          } else {
            this.dataZ1.forEach((item, index) => {
              if (index < max) {
                // 当data数组的下标小于max时
                newData.push(item)
              }
            })
          }

          this.frontDataZ1 = newData // 将新增的数据赋值到要显示的数组中
        }
      }
    },
    handleSearch1(val) {
      this.valueData1 = val
      if (!val) {
        this.dataZ1 = JSON.parse(JSON.stringify(this.list))
      } else {
        this.frontDataZ1 = []
        this.scrollPage1 = 1
        this.dataZ.forEach((item) => {
          if (item.styleTypeName.indexOf(val) >= 0) {
            this.frontDataZ1.push(item)
          }
        })
        this.allDataZ1 = this.frontDataZ1
        this.frontDataZ1 = this.frontDataZ1.slice(0, 100)
      }
    },
    handleChange(value) {
      let item = this.list.find((item) => item.styleId === value)
      this.$emit('handleChange', item)
    },
    handleInputChange() {
      let obj = {}
      obj['styleTypeName'] = this.localValue.trim()
      this.$emit('handleChange', obj)
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.serach-select {
  position: relative;
  max-width: 300px;
  flex: 1;
}
</style>
